/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useEffect } from "react";
import Select from "react-select";
import CustomGoogleAutoComplete from "../layout-new-theme/google-input/CustomGoogleAutoComplete";
import LocationPinIcon from "../../icons/form-icons/location-pin.svg";
import {  usageDurationOptions, } from "../../utils/dataLists";
import { generateRandom } from "../../utils/Calculator";

const ModalEditBookingAdmin = ({
  text = "",
  handleClose,
  handleContinue,
  show,
  booking,
  newTotalBooking = 0,
  vehicletypes = [],
  submitAction,
  loading,
}) => {
  const [vehicletypesOptions, setVehicletypesOptions] = useState([]);
  const [data, setData] = useState({
    booking_type:
      booking.bookingType === "byTheHour"
        ? "By The Hour"
        : booking.bookingType === "specifiedDestination" &&
          booking.isAirportTransfer
        ? "Airport Transfer"
        : "Specified Destination",
    pickup_location: booking.pickupLocation,
    dropoff_location: booking.destinations,
    usage_duration : booking?.usageDuration, 
    passengers_adult: booking.pax[0]?.paxcount,
    passengers_child: booking.pax[1]?.paxcount,
    passengers_toddler: booking.pax[2]?.paxcount,
    passengers_baby: booking.pax[3]?.paxcount,
    luggage_cabin_size: booking?.luggage[1]?.luggagecount,
    luggage_checkin_size: booking?.luggage[0]?.luggagecount,
    vehicles: booking.bookingInfo,
    total: newTotalBooking,
    travel_area : booking?.bookingInfo[0].travelArea,
    notes : "", 
  });

  const handleOnSubmit = () => {
    submitAction(booking._id, false, "edited", newTotalBooking, data); 
  };

  console.log(booking.destinations);

  const bookingTypeOptions = [
    { value: "By The Hour", label: "By The Hour" },
    { value: "Specified Destination", label: "Specified Destination" },
    { value: "Airport Transfer", label: "Airport Transfer" },
  ];

  const travelAreaOptions = [
    {
      value: "withinCity",
      label: "Within the city/prefecture",
    },
    {
      value: "neighboringPerfecture",
      label: "To the neighboring city/prefecture",
    },
    {
      value: "undecided",
      label: "Undecided",
    },
  ];

  

  useEffect(() => {
    setVehicletypesOptions(
      vehicletypes.map((d) => ({
        value: d._id,
        label: d.typename,
      }))
    );
  }, []);

  useEffect(() => {
    setData({ ...data, total: newTotalBooking });
  }, [newTotalBooking]);

  const handleChageForm = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleChangeBookingType = (selectedOption) => {
    setData((prevData) => ({
      ...prevData,
      booking_type: selectedOption.value,
      travel_area: selectedOption.value !== "By The Hour" ? "" : prevData.travel_area,
    }));
  };

  const handleChangeTravelArea = (selectedOption) => {
    setData({ ...data, travel_area: selectedOption.value });
  };

  const handleChangeUsageDuration = (selectedOption) => {
    setData({ ...data, usage_duration: selectedOption.value });
  };
  const handleUpdateDropLocation = (loc, index) => {
    setData((prevData) => {
      const updatedLocations = [...prevData.dropoff_location];
      updatedLocations[index] = {
        value : generateRandom(1000, 200000),
        label : loc.name + ", " + loc.formatted_address,
        lat :  loc.geometry.location.lat,
        lon :  loc.geometry.location.lng,
        address_components : loc.address_components,
        formatted_address :  loc.name + ", " + loc.formatted_address,
        prefecture : loc.prefecture,
      };
      
      return {
        ...prevData,
        dropoff_location: updatedLocations,
      };
    });

  };

  const onChangePickupLocation = (loc) => {
    console.log(loc);
    setData({
      ...data,
      pickup_location: {
        lat: loc.geometry.location.lat,
        lon: loc.geometry.location.lng,
        label: loc.name + ", " + loc.formatted_address,
        formatted_address: loc.name + ", " + loc.formatted_address,
        value: loc.place_id,
        city: loc.address_components.find((c) =>
          c.types[0].includes("administrative_area_level_1")
        ).long_name,
      },
    });
  };

  const handleUpdateVehicleName = (index, selectedOption) => {
    setData((prevData) => {
      const vehicles = [...prevData.vehicles];
      vehicles[index] = {
        ...vehicles[index],
        vehicleTypeName: selectedOption.label,
        vehicleTypeID: vehicletypes.find((i) => i.typename === selectedOption.label),
      };
      return {
        ...prevData,
        vehicles: vehicles,
      };
    });

  };

  const handleAddDestination = () => {
    setData((prevData) => ({
      ...prevData,
      dropoff_location: [...prevData.dropoff_location, { }], 
    }));
  };

  const handleDeleteDestination = (indexToRemove) => {
    setData((prevData) => ({
      ...prevData,
      dropoff_location: prevData.dropoff_location.filter(
        (_, index) => index !== indexToRemove
      ),
    }));
  };

  const updateChildSeatCount = (index, change, type) => {
    // Salin data untuk diupdate
    const newVehicles = [...data.vehicles];

    // Temukan childSeat yang sesuai
    const childSeat = newVehicles[index].childSeat.find(
      (i) => i.childSeatType === type
    );

    if (childSeat) {
      // Tambahkan atau kurangi childSeatCount sesuai parameter 'change'
      childSeat.childSeatCount = Math.max(0, childSeat.childSeatCount + change);
    }

    // Perbarui state dengan data baru
    setData({ ...data, vehicles: newVehicles });
  };

  const handleChageTotalForm = (name, type) => {
    switch (type) {
      case "increment":
        console.log("aa");
        setData((prevData) => ({
          ...prevData,
          [name]: (prevData[name] || 0) + 1,
        }));
        break;
      case "decrement":
        setData((prevData) => ({
          ...prevData,
          [name]: Math.max((prevData[name] || 0) - 1, 0),
        }));
        break;
      default:
        break;
    }
  };

  return (
    <>
      {show && <div className="modal-backdrop show"></div>}
      <div
        className={show ? "modal fade show" : "modal fade"}
        role="dialog"
        style={{ display: show ? "block" : "none" }}
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered "
          role="document"
        >
          <div className="modal-content border-top-gold">
            <div className="d-flex justify-content-between m-3">
              <h4 className="">{text}</h4>
              <button
                onClick={handleClose}
                type="button"
                className="text-end btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <hr className="m-0" />
            <div className="modal-body ">
              <div className="mb-3">
                <label className="form-label">Transfer Type</label>
                <Select
                  name="booking_type"
                  options={bookingTypeOptions}
                  value={bookingTypeOptions.find(
                    (option) => option.value === data.booking_type
                  )}
                  onChange={handleChangeBookingType}
                />
              </div>
              {data.booking_type === "By The Hour" && (
                <div className="mb-3">
                <label className="form-label">Travel Area</label>
                <Select
                  name="booking_type"
                  options={travelAreaOptions}
                  value={travelAreaOptions.find(
                    (option) => option.value === data.travel_area
                  )}
                  onChange={handleChangeTravelArea}
                />
              </div>
              )}
              <div className="mb-3">
                <label className="form-label">Usage Duration</label>
                <Select
                  name="usage_duration"
                  options={usageDurationOptions}
                  value={usageDurationOptions.find(
                    (option) => option.value === data.usage_duration.toString()
                  )}
                  onChange={handleChangeUsageDuration}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Pickup Location</label>
                <div className="col-12 mb-3">
                  <div className="form-floating position-relative">
                    <CustomGoogleAutoComplete
                      id="pickup1"
                      placeholder="Pick-up: Airport, Hotel, Attraction, etc. "
                      onPlaceSelected={onChangePickupLocation}
                      defaultValue={data.pickup_location.label}
                      invalidText={
                        "SpecifiedDestination.Pickup.Specific.Validation"
                      }
                    />
                    <span className="position-absolute translate-middle-y top-50 start-10 p-1rem">
                      <div className="d-flex justify-content-center">
                        <img alt="img" src={LocationPinIcon} />
                      </div>
                    </span>
                    <label htmlFor="pickUpAddress" className="label-icon">
                      Pickup Location
                    </label>
                  </div>
                </div>
              </div>
              <label className="form-label">Dropoff Location</label>
              {data.dropoff_location.map((d, index) => (
                <div className="col-12 mb-3">
                  <div className="d-flex gap-2">
                    <div className="col">
                    <div className="form-floating position-relative">
                      <CustomGoogleAutoComplete
                        placeholder="Drop-ooff: Airport, Hotel, Attraction, etc. "
                        onPlaceSelected={(loc) => {
                          handleUpdateDropLocation(loc, index);
                        }}
                        defaultValue={d.label}
                        invalidText={
                          "SpecifiedDestination.Dropoff.Specific.Validation"
                        }
                      />
                      <span className="position-absolute translate-middle-y top-50 start-10 p-1rem">
                        <div className="d-flex justify-content-center">
                          <img alt="img" src={LocationPinIcon} />
                        </div>
                      </span>
                      <label className="label-icon">
                        Drop Off Location
                      </label>
                    </div>
                    </div>
                    <div className="col-auto">
                    {data.dropoff_location.length > 1 && (
                      <button
                        className="btn btn-primary rounded"
                        onClick={() => handleDeleteDestination(index)}
                      >
                        <i className="bi bi-dash"></i>
                      </button>
                    )}
                    </div>
                  </div>
                </div>
                // <div className="mb-3" key={index}>
                //   <div className="d-flex gap-1">
                //     <textarea
                //       className="col form-control"
                //       id=""
                //       rows={1}
                //       name="dropoff_location"
                //       onChange={(e) =>
                //         handleUpdateDropLocation(index, e.target.value)
                //       }
                //       value={d.label}
                //     ></textarea>
                //     {data.dropoff_location.length > 1 && (
                //       <button
                //         className="btn btn-primary rounded"
                //         onClick={() => handleDeleteDestination(index)}
                //       >
                //         <i className="bi bi-dash"></i>
                //       </button>
                //     )}
                //   </div>
                // </div>
              ))}
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-primary text-end rounded"
                  onClick={handleAddDestination}
                >
                  <i className="bi bi-plus"></i> Add Destination
                </button>
              </div>

              <div className="row mt-3">
                <div className="col-md-3">
                  <label className="form-label">Passengers - Adult</label>
                  <div className="d-flex align-items-end gap-1">
                    <div className="mb-3">
                      <div className="col-auto">
                        <button
                          className="btn btn-primary rounded"
                          onClick={() =>
                            handleChageTotalForm(
                              "passengers_adult",
                              "decrement"
                            )
                          }
                        >
                          <i className="bi bi-dash"></i>
                        </button>
                      </div>
                    </div>
                    <div className="mb-3 col">
                      <textarea
                      readOnly
                        className="form-control"
                        id=""
                        rows={1}
                        name="passengers_adult"
                        onChange={(e) => handleChageForm(e)}
                        value={data.passengers_adult}
                      ></textarea>
                    </div>
                    <div className="mb-3">
                      <div className="col-auto">
                        <button
                          className="btn btn-primary rounded"
                          onClick={() =>
                            handleChageTotalForm(
                              "passengers_adult",
                              "increment"
                            )
                          }
                        >
                          <i className="bi bi-plus"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <label className="form-label">Passengers - Child</label>
                  <div className="d-flex align-items-end gap-1">
                    <div className="mb-3">
                      <div className="col-auto">
                        <button
                          className="btn btn-primary rounded"
                          onClick={() =>
                            handleChageTotalForm(
                              "passengers_child",
                              "decrement"
                            )
                          }
                        >
                          <i className="bi bi-dash"></i>
                        </button>
                      </div>
                    </div>
                    <div className="mb-3 col">
                      <textarea
                        readOnly
                        className="form-control"
                        id=""
                        rows={1}
                        name="passengers_child"
                        onChange={(e) => handleChageForm(e)}
                        defaultValue={booking.pax[1]?.paxcount}
                        value={data.passengers_child}
                      ></textarea>
                    </div>
                    <div className="mb-3">
                      <div className="col-auto">
                        <button
                          className="btn btn-primary rounded"
                          onClick={() =>
                            handleChageTotalForm(
                              "passengers_child",
                              "increment"
                            )
                          }
                        >
                          <i className="bi bi-plus"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <label className="form-label">Passengers - Toddler</label>
                  <div className="d-flex align-items-end gap-1">
                    <div className="mb-3">
                      <div className="col-auto">
                        <button
                          className="btn btn-primary rounded"
                          onClick={() =>
                            handleChageTotalForm(
                              "passengers_toddler",
                              "decrement"
                            )
                          }
                        >
                          <i className="bi bi-dash"></i>
                        </button>
                      </div>
                    </div>
                    <div className="mb-3 col">
                      <textarea
                        readOnly
                        className="form-control"
                        id=""
                        rows={1}
                        name="passengers_toddler"
                        onChange={(e) => handleChageForm(e)}
                        defaultValue={data.passengers_toddler}
                        value={data.passengers_toddler}
                      ></textarea>
                    </div>
                    <div className="mb-3">
                      <div className="col-auto">
                        <button
                          className="btn btn-primary rounded"
                          onClick={() =>
                            handleChageTotalForm(
                              "passengers_toddler",
                              "increment"
                            )
                          }
                        >
                          <i className="bi bi-plus"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Passengers - Baby
                  </label>
                  <div className="d-flex align-items-end gap-1">
                    <div className="mb-3">
                      <div className="col-auto">
                        <button
                          className="btn btn-primary rounded"
                          onClick={() =>
                            handleChageTotalForm("passengers_baby", "decrement")
                          }
                        >
                          <i className="bi bi-dash"></i>
                        </button>
                      </div>
                    </div>
                    <div className="mb-3 col">
                      <textarea
                        readOnly
                        className="form-control"
                        id=""
                        rows={1}
                        name="passengers_baby"
                        onChange={(e) => handleChageForm(e)}
                        defaultValue={data.passengers_baby}
                        value={data.passengers_baby}
                      ></textarea>
                    </div>
                    <div className="mb-3">
                      <div className="col-auto">
                        <button
                          className="btn btn-primary rounded"
                          onClick={() =>
                            handleChageTotalForm("passengers_baby", "increment")
                          }
                        >
                          <i className="bi bi-plus"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Luggage - Cabin Size
                  </label>
                  <div className="d-flex align-items-end">
                    <div className="mb-3">
                      <div className="col-auto">
                        <button
                          className="btn btn-primary rounded"
                          onClick={() =>
                            handleChageTotalForm(
                              "luggage_cabin_size",
                              "decrement"
                            )
                          }
                        >
                          <i className="bi bi-dash"></i>
                        </button>
                      </div>
                    </div>
                    <div className="mb-3 col">
                      <textarea
                        className="form-control"
                        id=""
                        rows={1}
                        name="luggage_cabin_size"
                        readOnly={true}
                        defaultValue={data.luggage_cabin_size}
                      ></textarea>
                    </div>
                    <div className="mb-3">
                      <div className="col-auto">
                        <button
                          className="btn btn-primary rounded"
                          onClick={() =>
                            handleChageTotalForm(
                              "luggage_cabin_size",
                              "increment"
                            )
                          }
                        >
                          <i className="bi bi-plus"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Luggage - Checkin Size
                  </label>
                  <div className="d-flex align-items-end">
                    <div className="mb-3">
                      <div className="col-auto">
                        <button
                          className="btn btn-primary rounded"
                          onClick={() =>
                            handleChageTotalForm(
                              "luggage_checkin_size",
                              "decrement"
                            )
                          }
                        >
                          <i className="bi bi-dash"></i>
                        </button>
                      </div>
                    </div>
                    <div className="mb-3 col">
                      <textarea
                        className="form-control"
                        id=""
                        rows={1}
                        name="luggage_checkin_size"
                        readOnly={true}
                        defaultValue={data.luggage_checkin_size}
                      ></textarea>
                    </div>
                    <div className="mb-3">
                      <div className="col-auto">
                        <button
                          className="btn btn-primary rounded"
                          onClick={() =>
                            handleChageTotalForm(
                              "luggage_checkin_size",
                              "increment"
                            )
                          }
                        >
                          <i className="bi bi-plus"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {data.vehicles.map((item, index) => {
                return (
                  <div key={index}>
                    <h6>Vehicle {index + 1}</h6>
                    <div className="mb-3">
                      <label className="form-label">
                        Car Type 
                      </label>
                      <Select
                        name="booking_type"
                        options={vehicletypesOptions}
                        value={vehicletypesOptions.find(
                          (option) => option.label === item.vehicleTypeName
                        )}
                        onChange={(selectedOption) =>
                          handleUpdateVehicleName(index, selectedOption)
                        }
                      />
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Child Seats & Booster Seats - Infant
                          </label>
                          <div className="d-flex align-items-end gap-1">
                            <div className="col-auto">
                              <button
                                className="btn btn-primary rounded"
                                onClick={() =>
                                  updateChildSeatCount(index, -1, "Infant")
                                }
                              >
                                <i className="bi bi-dash"></i>
                              </button>
                            </div>
                            <textarea
                              className="form-control col"
                              id=""
                              rows={1}
                              value={
                                (
                                  item.childSeat.find(
                                    (i) => i.childSeatType === "Infant"
                                  ) || {}
                                ).childSeatCount || 0
                              }
                            ></textarea>
                            <button
                              className="btn btn-primary rounded"
                              onClick={() =>
                                updateChildSeatCount(index, 1, "Infant")
                              }
                            >
                              <i className="bi bi-plus"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Child Seats & Booster Seats - Toddler
                          </label>
                          <div className="d-flex align-items-end gap-1">
                            <button
                              className="btn btn-primary rounded"
                              onClick={() =>
                                updateChildSeatCount(index, -1, "Toddler")
                              }
                            >
                              <i className="bi bi-dash"></i>
                            </button>
                            <textarea
                              className="form-control col"
                              id=""
                              rows={1}
                              value={
                                (
                                  item.childSeat.find(
                                    (i) => i.childSeatType === "Toddler"
                                  ) || {}
                                ).childSeatCount || 0
                              }
                            ></textarea>

                            <button
                              className="btn btn-primary rounded"
                              onClick={() =>
                                updateChildSeatCount(index, 1, "Toddler")
                              }
                            >
                              <i className="bi bi-plus"></i>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Child Seats & Booster Seats - Child
                          </label>
                          <div className="d-flex align-items-end gap-1">
                            <button
                              className="btn btn-primary rounded"
                              onClick={() =>
                                updateChildSeatCount(index, -1, "Child")
                              }
                            >
                              <i className="bi bi-dash"></i>
                            </button>
                            <textarea
                              className="form-control col"
                              id=""
                              rows={1}
                              value={
                                (
                                  item.childSeat.find(
                                    (i) => i.childSeatType === "Child"
                                  ) || {}
                                ).childSeatCount || 0
                              }
                            ></textarea>

                            <button
                              className="btn btn-primary rounded"
                              onClick={() =>
                                updateChildSeatCount(index, 1, "Child")
                              }
                            >
                              <i className="bi bi-plus"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Total Price
                </label>
                <textarea
                  className="form-control"
                  id=""
                  rows={1}
                  name="total_price"
                  readOnly={true}
                  onChange={(e) => handleChageForm(e)}
                  value={data.total.toLocaleString()}
                ></textarea>
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Message Inquiry
                </label>
                <textarea
                  className="form-control"
                  id=""
                  rows={1}
                  name="notes"
                  onChange={(e) => handleChageForm(e)}
                  defaultValue={booking.message.map((d) => d.note)}
                ></textarea>
              </div>
              <div className="mb-3 d-grid">
                {loading ? (<p className="text-center">Updating data, please do not refresh the page</p>) : ( <button className="btn btn-primary btn-lg" onClick={handleOnSubmit}>Submit</button>)}
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalEditBookingAdmin;
